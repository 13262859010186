import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { PlayButtonClick } from '../Svg'

const ClickVideo = ({ className, src, autoplay }) => {
	const videoRef = useRef()
	const [playing, setPlaying] = useState(autoplay)

	const handleClick = () => {
		setPlaying(!playing)
	}

	useEffect(() => {
		if(playing){
			videoRef?.current?.play()
		} else {
			videoRef?.current?.pause()
		}
	}, [playing])
	return (
		<Wrap className={className} onClick={handleClick}>
			<video ref={videoRef} className={className} playsInline muted loop>
				<source src={src ?? './videos/placeholder.mp4'} />
			</video>
			{!playing &&
				<Play />	
			}
		</Wrap>
	)
}

const Wrap = styled.button`
	width: auto;
	height: 100%;
	position: relative;
	video{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`

const Play = styled(PlayButtonClick)`
	width: 60px;
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 10;
	transform: translate(-50%, -50%);
`


ClickVideo.propTypes = {
	className: PropTypes.string
}

export default ClickVideo